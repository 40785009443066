import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import SEO from "../components/seo";
import SiteHeader from "../components/SiteHeader";
import H1 from "../components/typography/H1";

const Wrapper = styled.div`
  width: 80%;
  max-width: 500px;
  margin: auto;
`;

const NewsletterPage = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.mailblast.io/javascripts/mb-embed-1-2.js";
    document.body.appendChild(script);
  }, []);

  let title = (
    <H1 className="mb-form-title" secondary>
      Subscribe to our newsletter
    </H1>
  );
  let subtitle = (
    <p>
      We send occasional updates about our products and new launches. <br />
      <strong>No spam, ever.</strong>
    </p>
  );

  return (
    <LayoutWrapper>
      <SEO
        title="Newsletter"
        description="Stay up to date with Boxy Suite and our other products. No spam, ever!"
      />
      <SiteHeader dark />
      <LayoutSection width="38em">
        <div className="mb-embed">
          <link
            rel="stylesheet"
            media="screen"
            href="https://cdn.mailblast.io/stylesheets/mb-embed-1-2.css"
          />

          <h2
            className="mb-message mb-success-message"
            default-message="Thank you for Subscribing!"
          />
          <h2 className="mb-message mb-error-message" />

          <form
            method="post"
            action="https://www.mailblast.io/lists/a1ccbc62-ae8d-459c-8dfe-76f2a4944259/subscribers/create_external"
            className="mb-form"
            noValidate="novalidate"
          >
            {title}
            {subtitle}

            <div className="mb-form-group">
              <label>Email (required)</label>
              <input
                type="email"
                name="subscriber[email]"
                className="mb-email"
                required=""
                aria-required="true"
              />
            </div>

            <div className="mb-form-group">
              <label className="mb-name">First Name</label>
              <input type="text" name="subscriber[first_name]" className="mb-name mb-first-name" />
            </div>

            <div className="mb-form-group">
              <label className="mb-name">Last Name</label>
              <input type="text" name="subscriber[last_name]" className="mb-name mb-last-name" />
            </div>

            <div className="mb-form-group-tk" aria-hidden="true">
              <input
                type="text"
                name="mb_a1ccbc62ae8d459c8dfe76f2a4944259"
                tabIndex="-1"
                value=""
                readOnly
              />
            </div>

            <div className="mb-form-group mb-subscribe">
              {/* redirects somewhere on success */}
              {/* <input
                type="hidden"
                name="success_redirect"
                className="mb-subscribe-redirect-url"
                value="https://superlinear.co/"
              /> */}
              <button type="submit" className="button mb-subscribe">
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </LayoutSection>
      <Footer />
    </LayoutWrapper>
  );
};

export default NewsletterPage;
